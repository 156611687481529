import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

const Office = styled.div`
  ${tw`w-3/4 m:w-1/3 l:w-1/6 p-2 flex flex-col flex-grow font-body text-white text-center`}

  font-size: 15px;
  line-height: 1.47;
  margin-bottom: 1rem;

  img {
    height: 117px;
    margin-bottom: 12px;

    &#icon-ckelkb7lbmxcz0957el7msdbq {
      height: 128px;
      margin-bottom: 0;
    }
  }

  .office-name {
    ${tw`font-display font-bold`}
    font-size: 17px;
    line-height: 1.53;
    margin-bottom: 10px;
  }

  .office-name,
  .address {
    p {
      padding: 0;
    }
  }
`

const Offices = ({ className, ...rest }) => {
  const data = useStaticQuery(graphql`
    query OFFICE_LOCATIONS {
      gcms {
        contentBlocks(
          where: { identifier: "office-location" }
          orderBy: order_ASC
        ) {
          id
          icon {
            url
          }
          title
          headerContent {
            html
          }
          mainContent {
            html
          }
        }
      }
    }
  `)
  const offices = data.gcms.contentBlocks

  return (
    <div
      className={`w-full flex flex-wrap justify-center l:justify-between pt-8 pb-16 ${
        className ? className : ""
      }`}
      {...rest}
    >
      {offices.map((office) => (
        <Office key={office.id} id={office.id} className="office">
          <div className="h-40 flex flex-col items-center justify-end py-4">
            <img src={office.icon?.url} alt="" id={`icon-${office.id}`} />
          </div>
          <div
            className="office-name"
            dangerouslySetInnerHTML={{ __html: office.headerContent?.html }}
          />
          <div
            className="address"
            dangerouslySetInnerHTML={{ __html: office.mainContent?.html }}
          />
        </Office>
      ))}
    </div>
  )
}

export default Offices
