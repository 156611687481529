import React from "react"
import { Link, graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { InsightsFragment, PageFragment } from "components/fragments"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"

import Layout from "components/layout"
import SEO from "components/seo"
import FeaturedTestimonial from "components/sections/testimonial"
import FeaturedVideo from "components/sections/featured-video"
import SlantedSection from "components/sections/slant"
import ButtonLE from "components/button-le"
import Button from "components/button"
import Offices from "components/offices"
import BasicBackgroundSection from "components/basic-background-section"
import BgCompany from "images/bg/company.jpg"
import { boardMemberPath } from "utils/company"
import { getSharePreviewUrls } from "utils/share-preview"
import NewsroomAnnouncements from "components/newsroom/announcements"
import NewsroomLeading from "components/newsroom/leading"

const ContentWrapper = styled.div`
  &.profiles {
    background: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
  }
`

const Section = styled.div`
  ${tw`px-2 flex flex-wrap items-center justify-center max-w-s mx-auto m:max-w-m l:max-w-l`}
  a {
    transition: background 200ms;
  }
`

const Main = styled(Section)`
  ${tw`font-body text-center text-grey-15 flex-col`}

  h2 {
    ${tw`font-display py-4`}
    font-size: 30px;
    line-height: 35px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px;
    }
    @media (min-width: 1024px) {
      font-size: 50px;
      line-height: 48px;
    }

    &.leadership {
      ${tw`pt-20`}
    }
  }

  p {
    ${tw`py-4`}
    font-size: 15px;
    line-height: 27px;
  }
`

const DarkSection = styled(Main)`
  ${tw`font-body text-white`}
`

const Title = styled.h1`
  ${tw`font-display text-center`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    line-height: 79px;
  }
`

const Tagline = styled.p`
  ${tw`text-center w-full m:w-8/12 py-4`}
  font-size: 15px;
  line-height: 27px;
`

const Header2 = styled.div`
  ${tw`text-center m:text-left w-full l:w-7/12`}

  h2 {
    ${tw`font-display`}
    font-size: 30px;
    line-height: 35px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px;
    }
    @media (min-width: 1024px) {
      font-size: 50px;
      line-height: 67px;
    }
  }

  p {
    ${tw`py-4`}
    font-size: 15px;
    line-height: 27px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  }
`

const Profiles = styled.div`
  ${tw`w-full flex flex-row flex-wrap pt-8 pb-16`}

  max-width: 1000px;
`

const Profile = styled(Link)`
  ${tw`w-full flex flex-col font-display text-priority-lightP1 text-left p-2`}

  @media (min-width: 640px) {
    ${tw`w-1/2`}
  }

  @media (min-width: 960px) {
    ${tw`w-1/3`}
  }

  @media (min-width: 1200px) {
    ${tw`w-1/4`}
  }

  .name {
    ${tw`text-xl leading-normal text-center`}
  }

  .position {
    ${tw`hidden font-body text-base leading-relaxed`}
  }

  .picture {
    ${tw`mb-4 mt-6`}

    border-radius: 5px;
  }

  &:hover {
    .name {
      ${tw`text-neonred`}
    }
  }
`

const Company = ({ data }) => {
  const { page, boardMembers } = data.gcms
  const { insightsArticleSerieses, contentBlocks } = page

  const announcementsCb = contentBlocks.find(
    ({ identifier }) => identifier === "company-announcements-section-header"
  )
  const leadingCb = contentBlocks.find(
    ({ identifier }) => identifier === "leading-section"
  )
  const leadingSeries = insightsArticleSerieses?.find(
    ({ identifier }) => identifier === "leading-articles"
  )
  const aboutUsCb = contentBlocks.find(
    ({ identifier }) => identifier === "company-about-us-section"
  )

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}

      <BasicBackgroundSection
        image={BgCompany}
        size="cover"
        bgPosition="center"
        repeat="no-repeat"
      >
        <Section className="pt-32 m:pt-48 text-white font-body">
          <Title>
            When science meets art:
            <br />
            <span className="text-yellow">MAGIC HAPPENS</span>
          </Title>
          <Tagline className="font-display">
            Discover why we believe in the magic of content and the impact it
            has on people’s lives.
          </Tagline>
          <Tagline>
            We work with the biggest media and technology companies on the
            planet to drive investment, production, acquisition, marketing and
            distribution strategies on all continents around the planet.
          </Tagline>
        </Section>
        <Section className="pb-40 text-white font-body">
          <ButtonLE className="mt-6" to="#about-us">
            Find out more
          </ButtonLE>
        </Section>
        {aboutUsCb && (
          <Section
            className="py-32 text-white l:justify-start font-body"
            id="about-us"
          >
            <Header2>
              <div
                dangerouslySetInnerHTML={{
                  __html: aboutUsCb.headerContent.html,
                }}
              />
              <Link to={aboutUsCb.linkUrl}>
                <Button className="small purple glow">
                  {aboutUsCb.callToAction}
                </Button>
              </Link>
            </Header2>
            <div className="hidden l:block l:w-5/12" />
          </Section>
        )}
      </BasicBackgroundSection>

      <SlantedSection className="bg-grey-95">
        <ContentWrapper className="profiles">
          <Main className="pt-20 pb-20 font-body">
            <h2 className="pt-20 leadership" id="leadership">
              Leadership
            </h2>
            <p>
              Our leadership team is made up of industry experts and experienced
              entrepreneurs.
            </p>
            <Profiles>
              {boardMembers.map((boardMember) => (
                <Profile key={boardMember.id} to={boardMemberPath(boardMember)}>
                  <GatsbyImage
                    image={getImage(boardMember.picture)}
                    alt={boardMember.fullName}
                    className="picture"
                  />
                  <div className="name">{boardMember.fullName}</div>
                </Profile>
              ))}
            </Profiles>
          </Main>
        </ContentWrapper>
      </SlantedSection>

      {announcementsCb && (
        <SlantedSection>
          <NewsroomAnnouncements
            contentBlock={announcementsCb}
            initialPageSize={6}
            className="company"
          />
        </SlantedSection>
      )}

      {leadingCb && leadingSeries && (
        <SlantedSection>
          <NewsroomLeading
            contentBlock={leadingCb}
            series={leadingSeries}
            className="company"
          />
        </SlantedSection>
      )}

      <SlantedSection className="bg-black">
        <DarkSection>
          <div className="py-32 w-full">
            {!!page && !!page.featuredTestimonial && (
              <FeaturedTestimonial testimonial={page.featuredTestimonial} />
            )}
            {!!page && !!page.featuredVideo && (
              <FeaturedVideo video={page.featuredVideo} />
            )}
            <h2>We are truly global</h2>
            <p>
              We are looking forward to helping you make the very best content
              decisions.
            </p>
            <Offices />
          </div>
        </DarkSection>
      </SlantedSection>
    </Layout>
  )
}

export const query = graphql`
  query CompanyPage {
    gcms {
      page(where: { path: "/company" }) {
        ...PageData
        insightsArticleSerieses {
          identifier
          insightsArticles {
            ...InsightsData
            pressLogo {
              url(
                transformation: {
                  image: { resize: { width: 250, fit: clip } }
                  document: { output: { format: webp } }
                }
              )
            }
          }
        }
      }
      boardMembers(orderBy: order_ASC) {
        id
        fullName
        position
        picture {
          altText
          url
          base64
          mimeType
          height
          width
          handle
          gatsbyImageData(width: 512, placeholder: "blurred")
        }
        path
      }
    }
  }
`

export default Company
